import firebase from 'firebase/app'
import { notification } from 'antd'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'
// import firebaseConfig from './firebaseConfig_webg'
// import firebaseConfig from './firebaseConfig_bnithai'
import firebaseConfig from './firebaseConfig_shin-a'
// import firebaseConfig from './firebaseConfig_bni-integrity'
// import firebaseConfig from './firebaseConfig_ybkk'
// import firebaseConfig from './firebaseConfig_housed'
// import firebaseConfig from './firebaseConfig_gentlestots'
// import firebaseConfig from './firebaseConfig_salepage'
// import firebaseConfig from './firebaseConfig_getSmile'

// fb.database.ServerValue.TIMESTAMP
// fb.firestore.FieldValue.serverTimestamp()

firebase.initializeApp(firebaseConfig)
export const fb = firebase;
export const auth = firebase.auth()
export const db = firebase.firestore()
export const rdb = firebase.database()
export const storage = firebase.storage()
// export const functions = firebase.functions().region('asia-southeast2')

export async function login(email, password) {
  return auth
    .signInWithEmailAndPassword(email, password)
    .then(() => true)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}

export async function register(email, password, firstName, lastName, mobile) {
  return registerWithRole(email, password, firstName, lastName, mobile, "member")
}
export async function registerWithRole(email, password, firstName, lastName, mobile, role) {
  return auth
    .createUserWithEmailAndPassword(email, password)
    .then(response => {
      if (response.user) {
        const { uid } = response.user
        db.collection("user").doc(uid).set({
          uid: uid,
          role: role,
          firstName, 
          lastName, 
          mobile,
          email,
          createDate: fb.firestore.FieldValue.serverTimestamp(),
        })
      }
      return true
    })
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}
export async function createUserAccount(user_id, email, password, firstName, lastName, mobile, role) {
  return auth
    .createUserWithEmailAndPassword(email, password)
    .then(response => {
      if (response.user) {
        const { uid } = response.user
        db.collection("user").doc(user_id).update({
          uid: uid,
          updateDate: fb.firestore.FieldValue.serverTimestamp(),
        })
      }
      return true
    })
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}

export async function currentAccount() {
  let userLoaded = false
  function getCurrentUser(authUser) {
    return new Promise((resolve, reject) => {
      if (userLoaded) {
        resolve(authUser.currentUser)
      }
      const unsubscribe = authUser.onAuthStateChanged(user => {
        userLoaded = true
        unsubscribe()
        const getUserData = async () => {
          if (user) {
            const snapshot = await firebase.firestore().collection('user').where("uid", "==", user.uid).get()
            const result = snapshot.docs.map(doc => doc.data())
            const resultkey = snapshot.docs.map(doc => doc.id)
            const key = result.length > 0 ? resultkey[0] : null
            const userFields = result.length > 0 ? result[0] : null
            console.log("currentUser = ", userFields)

            const userAuthResult = snapshot.docs.map(doc => doc.data())
            const userAuth = userAuthResult.length > 0 ? userAuthResult[0] : null
            if(userFields){
              const mergedUser = Object.assign(user, {
                roles: userAuth.roles || '',
                // id: user.uid,
                // _uid: user.uid,
                id: key,
                firstName: userFields.firstName || '',
                lastName: userFields.lastName || '',
                nickName: userFields.nickName || '',
                mobile: userFields.mobile || '',
                role: userFields.role,
                // avatar: user.photoUrl,
                photo: userFields.photo,
                photoBackground: userFields.photoBackground,

                company: userFields.company,
                company_id: userFields.company_id,

                position: userFields.position,
                companyName: userFields.companyName,
                companyDesc: userFields.companyDesc,
                companyLogo: userFields.companyLogo,

                companyRole: userFields.companyRole,

                chapter: userFields.chapter,
                chapter_id: userFields.chapter_id,

                creditBalance: userFields.creditBalance,
                pointBalance: userFields.pointBalance,
              })
              return mergedUser
            }
          }
          return user
        }
        resolve(getUserData())
      }, reject)
    })
  }
  return getCurrentUser(auth)
}

export async function logout() {
  return auth.signOut().then(() => true)
}

export async function doGetUserProfile() {
  const docRef = db.collection("user").doc("c");
  docRef.get().then(function func(doc) {
      if (doc.exists) {
          console.log("Document data:", doc.data());
      } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
      }
  }).catch(function (error) {
      console.log("Error getting document:", error);
  });
}

// Auth ########################################################################

// Sign In
export const doSignInWithEmailAndPassword = (email, password) =>
  auth.signInWithEmailAndPassword(email, password)

// Sign Out
export const doSignOut = () => auth.signOut()

// // Password Reset
export const doPasswordReset = email =>
  // console.log("email for reset = ",email)
  auth
    .sendPasswordResetEmail(email)
    .then(function() {
      // Password reset email sent.
      console.log("Reset email = ", email)
      notification.info({
        message: "Reset Password",
        description: "Please check inbox / trash in your email for reset your password.",
      })
    })
    .catch(function(error) {
      // Error occurred. Inspect error.code.
      console.log("Error = ", error)
      notification.warning({
        message: error.code,
        description: error.message,
      })
    });

// Password Change
export const doPasswordUpdate = password => {
  if(password){
    fb.auth().onAuthStateChanged(function(user) {
      if(user){
        // auth.currentUser
        user.updatePassword(password)
        .then(function() {
          // Password reset email sent.
          // console.log("Reset email = ", email)
          notification.info({
            message: "Change Password",
            description: "Change your password success.",
          })
        })
        .catch(function(error) {
          // Error occurred. Inspect error.code.
          console.log("error = ", error)
          notification.warning({
            message: error.code,
            description: error.message,
          })
        })
      } else {
        // No user is signed in.
      }
    })
  }
}
  
  

// //Email Update
// export const doEmailUpdate = email =>
//   auth.currentUser.updateEmail(email).then(
//     db
//       .collection("user")
//       .doc("user3") //retrieve user name from fb
//       .update({
//         email: email
//       })
//   );

// //Update nick name
// export const doNickNameUpdate = nickname =>
//   db
//     .collection("user")
//     .doc("c")
//     .update({
//       name: nickname
//     });

// export const doBioUpdate = newBio =>
//   db
//     .collection("user")
//     .doc(localStorage.getItem("username"))
//     .update({
//       bio: newBio
//     });
