import React,{useState} from 'react';
import Helmet from 'react-helmet';
import 'react-lazy-load-image-component/src/effects/blur.css';
// import firebase from '../config/firebase_config';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';
import { fb, db, rdb, auth, storage } from 'services/firebase'
// import { fb, db, rdb, auth, storage, functions } from 'services/firebase'
import { notification } from 'antd';
import moment from 'moment';
import { indexOf, lastIndexOf } from 'lodash';
import { Notify }  from 'line-api'
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import DOMPurify from 'dompurify';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import XLSX from "xlsx";  

// const functions_host = fb.app().functions('asia-northeast1') // tokyo
// house-th
const functions_host = fb.app().functions('asia-southeast2') // Jakarta, Indonesia
// const functions_host = functions// 
 
// stats

// lineNotify({token:"r2cfdTsL51bFIqugJtyhfFYYGckMr12qupRJU85IAX5", messages:`your message`})
// googleTagManager({id: "GTM-NTFMB78"})
// googleAnalytic({tracking_id : "UA-184029321-1"})
// facebookPixel({pixel_id: "143207657566547"})
export const lineNotify = (config) => {
  const { token, messages } = config

  var addMessage = functions_host.httpsCallable('lineNotify');
  
  addMessage({ token: token, messages: messages }).then(function func(result) {
    console.log("result " , result);
    // Read result of the Cloud Function.
    // var sanitizedMessage = result.data.messages;
    // console.log( "sanitizedMessage" , sanitizedMessage );
  }).catch(function func(error) {
    console.log('error ', error);
    // // Getting the Error details.
    // var code = error.code;
    // console.log( "code" , code );
    // var message = error.message;
    // console.log( "message" , message );
    // var details = error.details;
    // console.log( "details" , details );
    // ...
  });


  // var full_message = [
  //   "",
  //   "Topic : "+(data.get('topic') != null ? data.get('topic') : ""),
  
  //   "Name  : "+(data.get('name') != null ? data.get('name') : ""),
  //   "Mobile: "+(data.get('mobile') != null ? data.get('mobile') : ""),
  //   "LineID: "+(data.get('lineId') != null ? data.get('lineId') : ""),
  //   "Email : "+(data.get('email') != null ? data.get('email') : ""),
  
  //   "Messages: "+(data.get('messages') != null ? data.get('messages') : "")
  // ].join('\n');
  //   // alert('A name was submitted: ' + full_message);
  // var addMessage = firebase.functions().httpsCallable('lineNotify');
  
  // addMessage({text:full_message}).then(function(result) {
  //   // Read result of the Cloud Function.
  //   var sanitizedMessage = result.data.text;
  //   console.log( "sanitizedMessage" , sanitizedMessage );
  // }).catch(function(error) {
  //   // Getting the Error details.
  //   var code = error.code;
  //   console.log( "code" , code );
  //   var message = error.message;
  //   console.log( "message" , message );
  //   var details = error.details;
  //   console.log( "details" , details );
  //   // ...
  // });
}

export const googleTagManager = (config) => {
  const { id } = config
  const tagManagerArgs = {
    gtmId: id
  }
  
  TagManager.initialize(tagManagerArgs)
}
export const googleAnalytic = (config) => {
  const { tracking_id } = config
  // ReactGA.initialize('UA-000000-01');
  const r = ReactGA.initialize(tracking_id);
  console.log("ReactGA", r)
  // console.log(window.location.pathname + window.location.search)
  // console.log(window.location.href)
  // console.log(window.location.pathname)
  // window.ga('set', 'page', window.location.pathname + window.location.search);
  // window.ga('send', 'pageview');
  // ReactGA.pageview(window.location.pathname + window.location.search);
  ReactGA.pageview(window.location.href);

}
export const facebookPixel = (config) => {
  const { pixel_id } = config
  const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };
  ReactPixel.init(pixel_id, advancedMatching, options);

  ReactPixel.pageView(); // For tracking page view
  // ReactPixel.track(event, data); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
  // ReactPixel.track('test_event_code', 'TEST95856');
  ReactPixel.track('TEST95856', 'test_event_code');
  // ReactPixel.trackSingle('PixelId', event, data); // For tracking default events.
  // ReactPixel.trackCustom(event, data); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
  // ReactPixel.trackSingleCustom('PixelId', event, data); // For tracking custom events.


  //     <!-- Facebook Pixel Code -->
  // <script>
  // !function(f,b,e,v,n,t,s)
  // {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  // n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  // if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  // n.queue=[];t=b.createElement(e);t.async=!0;
  // t.src=v;s=b.getElementsByTagName(e)[0];
  // s.parentNode.insertBefore(t,s)}(window, document,'script',
  // 'https://connect.facebook.net/en_US/fbevents.js');
  // fbq('init', '143207657566547');
  // fbq('track', 'PageView');
  // </script>
  // <noscript><img height="1" width="1" style="display:none"
  // src="https://www.facebook.com/tr?id=143207657566547&ev=PageView&noscript=1"
  // /></noscript>
  // <!-- End Facebook Pixel Code -->
}

export const sendLineNotify = (token, body) => {
  const { message, sticker, image } = body
  const notify = new Notify({
      token: token
  })
  notify.status().then(console.log)
  notify.status().then(()=>{console.log(notify.ratelimit)})
  notify.send({
      message: 'Test message',
      sticker: 'smile', // shorthand
      // sticker : { packageId: 1, id: 2 } // exact ids
      // image: 'test.jpg', // local file
      // image: { fullsize: 'http://example.com/1024x1024.jpg', thumbnail: 'http://example.com/240x240.jpg' } // remote url
  }).then(console.log)
  // { status: 200, message: 'ok' }
  
}

export const uploadFileSizeLimit = 1024000 // 1MB byte
// export const uploadFileSizeLimit = 512000 // 512 KB byte
// export const uploadFileSizeLimit = 256000 // 256 KB byte
export const defaultTimeformat = 'HH:mm:ss'
export const defaultDateformat = 'DD/MMM/YYYY'
export const defaultDateTimeformat = 'DD/MMM/YYYY HH:mm:ss'
export const defaultPageLimit = 10
export const defaultCreateBy = (user) =>{ return {createUser_id: user ? user.id : null, createUser: user ? `${user.firstName} ${user.lastName}` : null, createDate: fb.firestore.FieldValue.serverTimestamp()} }
export const defaultUpdateBy = (user) =>{ return {updateUser_id: user ? user.id : null, updateUser: user ? `${user.firstName} ${user.lastName}` : null, updateDate: fb.firestore.FieldValue.serverTimestamp()} }
export const secondsToTime = (firestoreTimestampt) => { 
  // console.log("firestoreTimestampt = ",firestoreTimestampt)
  return firestoreTimestampt ? moment(moment.unix(firestoreTimestampt.seconds),defaultTimeformat) : null
}
export const secondsToDate = (firestoreTimestampt) => { 
  // console.log("firestoreTimestampt = ",firestoreTimestampt)
  return firestoreTimestampt ? moment(moment.unix(firestoreTimestampt.seconds),defaultDateformat) : null
}
export const secondsToDateTime = (firestoreTimestampt) => { 
  // console.log("firestoreTimestampt = ",firestoreTimestampt)
  return firestoreTimestampt ? moment(moment.unix(firestoreTimestampt.seconds),defaultDateTimeformat) : null
}
export const secondsToTimeString = (firestoreTimestampt) => { 
  // console.log("firestoreTimestampt = ",firestoreTimestampt)
  return firestoreTimestampt ? moment(moment.unix(firestoreTimestampt.seconds),defaultTimeformat).format(defaultTimeformat) : null
}
export const secondsToDateString = (firestoreTimestampt) => { 
  // console.log("firestoreTimestampt = ",firestoreTimestampt)
  return firestoreTimestampt ? moment(moment.unix(firestoreTimestampt.seconds),defaultDateformat).format(defaultDateformat) : null
}
export const secondsToDateTimeString = (firestoreTimestampt) => { 
  // console.log("firestoreTimestampt = ",firestoreTimestampt)
  return firestoreTimestampt ? moment(moment.unix(firestoreTimestampt.seconds),defaultDateTimeformat).format(defaultDateTimeformat) : null
}
export const secToDateString = (seconds) => { 
  // console.log("firestoreTimestampt = ",firestoreTimestampt)
  return moment(moment.unix(seconds),defaultDateformat).format(defaultDateformat)
}
export const remainingFromNowInDays = (date) => {
  if(date){
    var date2 = secondsToDate(date)
    var now = new Date()
    // console.log("now = ", now)
    // console.log("date = ", date2)
    const result = getDifferenceInDays(now, date2).toLocaleString(undefined, {maximumFractionDigits:0})
    return result
  }
  else
   return ''
}
export function getDifferenceInDays(date1, date2) {
  const diffInMs = Math.abs(date2 - date1);
  return diffInMs / (1000 * 60 * 60 * 24);
}
export function getDifferenceInHours(date1, date2) {
  const diffInMs = Math.abs(date2 - date1);
  return diffInMs / (1000 * 60 * 60);
}
export function getDifferenceInMinutes(date1, date2) {
  const diffInMs = Math.abs(date2 - date1);
  return diffInMs / (1000 * 60);
}
export function getDifferenceInSeconds(date1, date2) {
  const diffInMs = Math.abs(date2 - date1);
  return diffInMs / 1000;
}


export const openNotificationWithIcon = (type, message, description) => {
  notification[type]({
    message: message,
    description: description,
  });
}

export const exportFileExcel = (fileName, dataList, nameList) => {
  const newList = [] 
  newList.push(nameList)
  dataList.forEach(data => {
    const row = []
    nameList.forEach(key => {
      row.push(data[key])
    })
    newList.push(row)
    // console.log('bb = ', bb)
  })
  // dataList.map(p => [p.firstName, p.companyName])
  
  // const newDataList = [nameList].concat(newList)
  console.log('newList = ', JSON.stringify(newList))
  console.log(fileName)
  /* convert state to workbook */
  const ws = XLSX.utils.aoa_to_sheet(newList)
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1")
  /* generate XLSX file and send to client */
  XLSX.writeFile(wb, fileName)
  // XLSX.writeFile(wb, "exportFile.xlsx")
}

export const numberFormat = new Intl.NumberFormat(
  {minimumFractionDigits: 2,
    maximumFractionDigits: 2,}
)
/** * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
firestore
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const getDocById = (setDoc, setDocId, docName, docId) => {
  getDocByIdAndSubFunction(setDoc, setDocId, docName, docId, ()=>{})
}
export const getDocByIdAndSubFunction = async (setDoc, setDocId, docName, docId, subFunction) => {
  // console.log("docId = ", docId)
  const id = docId
  if(id === undefined || id === null){
    console.log("docId = ", id)
    return null;
  }
  // if(id === 'new'){
  //   db.collection(docName).add({createDate: fb.firestore.FieldValue.serverTimestamp()})
  //   .then(function(docRef) {
  //     id = docRef.id
  //     update()
  //     console.log("new ID: ", id);
  //   })
  //   .catch(function(error) {
  //     console.error("Error adding document: ", error);
  //   });
  // }else
  fatchData()
  // console.log("docName = ", docName)
  // console.log("docId = ", id)
  function fatchData() {
    return Promise.resolve( 
      db.collection(docName)
        .doc(id).get().then((doc) => {
          console.log(`${docName} docId = ${doc.id} | doc.data() = ` , doc.data());
          setDocId(doc.id);       
          setDoc(doc.data()); 
          subFunction(doc.data())
          return doc.data()
        })
    ).then(function func(value) {
      // console.log("Success1 = ",value); // "Success"
      return value;
    }).catch(function func(err) {
      console.log("err = ",err); 
    })
  }
}
export const getDocAndNext = async (setDoc, setDocId, docName, docId, whereList, subFunction) => {
  // console.log("docId = ", docId)
  const id = docId
  // if(id === undefined || id === null){
  //   console.log("docId = ", id)
  //   return null;
  // }
  fatchData()
  function fatchData() {
    let collection = db.collection(docName)
    if(id){
      console.log("iddd = ", id)
      collection = collection.doc(id)
    }
    whereList.forEach(param => {
      const {key, value} = param
      collection = collection.where(key, '==', value)
      // Object.entries(param).map(([key,value])=>{
      //   console.log(key, value)
      //   collection = collection.where(key, '==', value)
      //   return true
      // })
    })  
    return Promise.resolve( 
      collection.get().then((docList) => {
        console.log("doc = ", docList)
        console.log("length = ", docList.docs.length)
        if(docList.docs.length !== 1){
          console.log("result size = ", docList.docs.length)
        } else {
          const doc = docList.docs[0]
          console.log("result 1 = ", doc)
          setDocId(doc.id);       
          setDoc(doc.data()); 
          subFunction(doc.data())
          return doc.data();
        } 

        // setDocId(doc.id);       
        // setDoc(doc.data()); 
        // subFunction(doc.data())
        // return docList.data()
      })
    ).then(function func(value) {
      // console.log("Success1 = ",value); // "Success"
      return value;
    }).catch(function func(err) {
      console.log("err = ",err); 
    })
  }
}
// export const getDocById = (setDoc, setDocId, docName, docId, sub) => {
//   // console.log("docId = ", docId)
//   if(docId){
//     db.collection(docName)
//     .doc(docId).get().then((doc) => {
//       console.log(`${docName} docId = ${doc.id} `);
//       console.log(`${docName} doc.data() = ` , JSON.stringify(doc.data()));
//       setDocId(doc.id);       
//       setDoc(doc.data());     
//       sub(doc)
//     })
//   }
// }
export const getCol = (firebase, setTable, tableName) => {
  getColOrderBy(firebase,setTable,tableName,null,null)
}
export const getColOrderBy = (firebase, setTable, tableName, orderByColumn, orderByMode) => {
  if(tableName != null && tableName.length > 0){
    let collection = db.collection(tableName);
    if(orderByColumn != null)
      collection = collection.orderBy(orderByColumn, orderByMode)
      // collection = collection.orderBy("createDate", "desc")
    collection = collection.limit(defaultPageLimit)
    collection.onSnapshot(function func(data){
        console.log(`fetch ${tableName} size = `, data.docs.length);
        setTable(data.docs.map(doc => ({...doc.data(), id:doc.id})));
    });
  }
}
export const getColCondition = (setTable, tableName, whereList, orderList) => {
    if(tableName != null && tableName.length > 0){
    let collection = db.collection(tableName);
    whereList.forEach(param => {
      const {key, value} = param
      collection = collection.where(key, '==', value)
    })
    // orderList.forEach(param => {
    //   const {key, value} = param
    //   collection = collection.orderBy(key, value)
    // })
    // collection = collection.orderBy("createDate", "desc")
    collection = collection.limit(defaultPageLimit)
    console.log("tableName = ", whereList)
    collection.onSnapshot(function func(data){
        console.log(`fetch ${tableName} size = `, data.docs.length);
        setTable(data.docs.map(doc => ({...doc.data(), id:doc.id})));
    });
    }
}
export const getDoc = (view,  docParam, whereList, nextFunction) => {
  const { docName, doc, setDoc, docId, setDocId } = docParam
  console.log("docId = ", docId)
  console.log("whereList = ", whereList)
  const id = docId
  // if(id === undefined || id === null){
  //   console.log("docId = ", id)
  //   return null;
  // }
  console.log("docName = ", docName)
  let collection = db.collection(docName)
  if(id && id !== undefined){
    console.log("get doc with id = ", id)
    collection = collection.doc(id)
    collection.get().then((snapshort) => {
      // console.log('snapshort = ',snapshort)
      if(snapshort.data()){
        console.log(`${docName} docId = ${snapshort.id} | doc.data() = ` , snapshort.data());
        setDocId(snapshort.id);       
        setDoc(snapshort.data()); 
        if(nextFunction)
          nextFunction(snapshort.data())
        return snapshort.data()
      }
    })
    .then(function func(value) {
      // console.log("Success1 = ",value); // "Success"
      return value;
    }).catch(function func(err) {
      console.log("err = ",err); 
    })
  }
  else if(whereList && whereList.length > 0){
    whereList.forEach(param => {
      const {key, operation, value} = param
      console.log("whereList key = ", key)
      console.log("whereList operation = ", operation)
      console.log("whereList value = ", value)
      if(key && operation && value)
        collection = collection.where(key, operation, value)
    })
    collection.onSnapshot(function func(snapshot){
      const resultSize = snapshot.docs.length
      console.log(`${docName} size = `, resultSize)
      if(resultSize > 0){
        const resultList = snapshot.docs.map(doc1 => ({...doc1.data(), id:doc1.id}))
        const result = resultList[0]
        setDocId(result.id);       
        setDoc(result); 
        if(nextFunction)
          nextFunction(result)
      }
    });
  }else{
    console.log("docId are where are empty")
    return null
  }
}
export const getList = (page, view,  docParam, whereList, orderList) => {
  const { docName, docList, setDocList } = docParam
  const { pageSize, setSize, first, setFirst, last, setLast, action } = page
  // console.log(docName)
  // console.log("whereList = ", whereList)
  // console.log("orderList = ", orderList)
  // console.log("next = ", last.data())
  if(docName != null && docName.length > 0){
    let collection = db.collection(docName);
    orderList.forEach(param => {
      const {orderBy, orderMode} = param
      // console.log("orderList orderBy = ", orderBy)
      // console.log("orderList orderMode = ", orderMode)
      // collection = collection.orderBy("createDate", "desc")
      if(orderBy && orderMode)
        collection = collection.orderBy(orderBy, orderMode)
    })
    if(whereList){
      let canSearchWithArray = true // Invalid query. You cannot use more than one 'array-contains' filter.
      whereList.forEach(param => {
      const {key, operation, value} = param
      // console.log("whereList key = ", key)
      // console.log("whereList operation = ", operation)
      // console.log("whereList value = ", value)
      if(key && operation && value)
        if( operation !== "array-contains" || (operation === "array-contains" && canSearchWithArray)){
          collection = collection.where(key, operation, value)
        }
        if( operation === "array-contains"){
          canSearchWithArray = false
          console.log("warn = limit search with array only one")
        }
      })
    }
    collection = collection.limit(pageSize || defaultPageLimit)
    if(action === "more" && last){
      collection = collection.startAfter(last)
    }
    collection.onSnapshot(function func(snapshot){
      const resultSize = snapshot.docs.length
      console.log(`${docName} size = `, resultSize)
      if(resultSize > 0){
        let result = snapshot.docs.map(doc => ({...doc.data(), id:doc.id}))
        if(action === "more" && last){
          if(docList && docList[0]!==undefined)
            result = [...docList, ...result]
          setDocList(result)
        }else
          setDocList(result)
        const firstResult = snapshot.docs[0]
        const lastResult = snapshot.docs[resultSize - 1]
        setLast(resultSize === pageSize ? lastResult : null)
        // console.log("first= ", firstResult.data())
        // console.log("last = ", lastResult.data())
      }else{
        setLast(null)
        if(action === "search")
          setDocList([])
      }
    });
    // const snapshot = await  first.get();
  }
}

// master data
export const loadMaster = (firebase ,master, setMaster, tableName) => {
  return loadMasterDynamic(firebase ,master, setMaster, tableName, null, null)
}
export const loadMasterDynamic = (firebase ,master, setMaster, tableName, labelName, valueName) => {
  if(!valueName)
    valueName = 'name'
  db.collection(tableName)
  // .orderBy("name","desc")
  .orderBy(labelName,"asc")
  .limit(defaultPageLimit)
  .onSnapshot(function func(data){
    // console.log("mater data = ", data.docs);
    // const list= (data.docs.map(doc => ({...doc.data(), value:doc.id} )));
    // const list= (data.docs.map(doc => ({label:doc.data().name, value:doc.data().name} )));
    // const list= (data.docs.map(doc => ({label:doc.data().name, value:doc.id} )));

    // const list= (data.docs.map(doc => ({ label : doc.data()[labelName || 'name'], value : doc.data()[valueName || 'name'] } )));
    const list = (data.docs.map(doc => (
      { label : doc.data()[labelName || 'name']
      , value : valueName === "id" ? doc.id : doc.data()[valueName] } 
    )));
    // console.log("master = ", list)
    const masterTemp = master;
    masterTemp[tableName] = list;
    setMaster(masterTemp);
  });
//   return ;
}
// { tableName: "zActiveStatus", label: "name", value: "name", whereList: [], orderList: [], limit: 100},
export const loadMasterData = (firebase ,master, setMaster, masterQuery) => {
  const { tableName, label, value, whereList, orderList, limit } = masterQuery
  let valueName = value
  if(!valueName)
    valueName = 'name'
  db.collection(tableName)
  // .orderBy("name","desc")
  .orderBy(label,"asc")
  .limit(limit || defaultPageLimit)
  .onSnapshot(function func(data){
    const list = (data.docs.map(doc => (
      { label : doc.data()[label || 'name']
      , value : valueName === "id" ? doc.id : doc.data()[valueName] } 
    )));
    // console.log(`${tableName} = `, list)
    const masterTemp = master;
    masterTemp[tableName] = list;
    setMaster(masterTemp);
  });
//   return ;
}


// <Col span={12}>
//   <Form.Item name="chapter" label="Chapter" rules={[ { required: false, }, ]}  >
//     <Select options={master.chapter} onChange={(v,e)=>{
//       changeSelect("chapter",v,e,
//         nextSelector(master, setMaster, 
//         [
//           { tableName: "user", label: ["firstName"," ","lastName"," (","nickName",")"], value: "id"
//           , whereList: [{key:"chapter_id",operation:"==",value:v}], orderList: [], limit: 1000},
//         ], eleOption, setEleOption, "userMember", v))
//       }} 
//       size="middle" placeholder="Select" allowClear showSearch filterOption={(input, option) => option.label.indexOf(input) >= 0} />
//   </Form.Item>
// </Col>
// { viewMode !== 'owner' && (
// <Col span={12}>
//   <Form.Item name="userMember" label="Member" rules={[ { required: false, }, ]}  >
//     <Select options={master.user} onChange={(v,e)=>{changeSelect("userMember",v,e)}} size="middle" placeholder="Select" allowClear showSearch filterOption={(input, option) => option.label.indexOf(input) >= 0} disabled={ eleOption && eleOption.userMember && eleOption.userMember.disabled === true} />
//   </Form.Item>
// </Col>
// )}

export const nextSelector = (master, setMaster, masterQuery, eleOption, setEleOption, nextSelectorName, id ) => {
  if(id){
    masterData(master, setMaster, masterQuery)
    setEleOption({...eleOption, [nextSelectorName] : { ...eleOption[nextSelectorName], disabled: false}})
  }else{
    setMaster({...master, user : []})
    setEleOption({...eleOption, [nextSelectorName] : { ...eleOption[nextSelectorName], disabled: true}})
  }
}

// masterData(master, setMaster, [
//   { tableName: "status", label: "name", value: "name", whereList: [], orderList: [], limit: 100},
//   { tableName: "training", label: "name", value: "id", whereList: [], orderList: [], limit: 100
//     , option:["price", "earlyBirdPrice"]},
//   { tableName: "chapter", label: "name", value: "id", whereList: [], orderList: [], limit: 100},
// { tableName: "chapter", label: "name", value: "id", whereList: [{key: "name",operation: "==",value: user.chapter}], orderList: [{orderBy: "none"}], limit: 100},
//   { tableName: "user", label: ["firstName"," ","lastName"," (","nickName",")"], value: "id", whereList: [], orderList: [], limit: 5000},
// ])

// { tableName: "training", label: "name", value: "id", 
//   whereList: [{key:"registerEndDate", operation:"<=", value: new Date()},], 
//   orderList: [{orderBy:"none"}], limit: 100},

export const masterData = (master, setMaster, masterQuery) => {
  let masterLog = []
  masterQuery.forEach((m) => { 
    // console.log("m = ", m)
    const { tableName, label, value, whereList, orderList, limit, option, nextFunction } = m
    let lableName = label
    let valueName = value
    if(Array.isArray(label))
      lableName = label[0]

    if(!valueName)
      valueName = 'name'
    let collection = db.collection(tableName)
    whereList.forEach(param => {
      const {key, operation, value: value2} = param
      // console.log(`${key} ${operation} ${value2}`)
      collection = collection.where(key, operation || '==', value2)
    })
    // console.log("lableName = ", lableName)
    // console.log("orderList = ", orderList)
    if(orderList && orderList.length > 0)
      orderList.forEach(param => {
        const {orderBy, orderMode} = param
        if(orderBy !== 'none')
          collection = collection.orderBy(orderBy, orderMode)
        // collection = collection.orderBy("createDate", "desc")
      })
    else{
      collection = collection.orderBy(lableName,"asc")
    }
    // db.collection(tableName)
    // .orderBy("name","desc")

    collection = collection.limit(limit || defaultPageLimit)
    collection.onSnapshot(function func(snapshot){
      // const list = (data.docs.map(doc => (
      //   { label : doc.data()[lableName || 'name']
      //   , value : valueName === "id" ? doc.id : doc.data()[valueName] } 
      // )))
      const list = snapshot.docs.map(doc => {
        const docData = doc.data()
        let labelResult = ""
        // console.log("label = ",label)
        if(Array.isArray(label))
          for (let i = 0; i < label.length; i+=1) {
            const keyName = label[i];
            if(docData[keyName] !== undefined)
              labelResult += docData[keyName]
            else
              labelResult += keyName
              // labelResult += ""
            // console.log("keyName = ",keyName)
            // console.log("labelResult = ",labelResult)
          }
        else
          labelResult = docData[lableName || 'name']
        // console.log("name all = ",labelResult)
        let optionParam = {}
        if(Array.isArray(option))
          for (let i = 0; i < option.length; i+=1) {
            const keyName = option[i];
            optionParam = {...optionParam, [keyName]: docData[keyName]}
            // console.log(keyName, docData[keyName])
          }
        return { label : labelResult, value : valueName === "id" ? doc.id : docData[valueName], option: {...optionParam} } 
      })
      // console.log(`${tableName} = `, list)
      // masterLog.push({name: tableName, value: list})
      masterLog = {...masterLog, [tableName]: list}
      const masterTemp = master
      // masterTemp[tableName] = list
      // setMaster(masterTemp)
      setMaster({...masterTemp ,...masterLog})
    })



    // collection.onSnapshot(function func(snapshot){
    //   const resultSize = snapshot.docs.length
    //   console.log(`${docName} size = `, resultSize)
    //   if(resultSize > 0){
    //     let result = snapshot.docs.map(doc => ({...doc.data(), id:doc.id}))
    //     if(action === "more" && last){
    //       if(docList && docList[0]!==undefined)
    //         result = [...docList, ...result]
    //       setDocList(result)
    //     }else
    //       setDocList(result)
    //     const firstResult = snapshot.docs[0]
    //     const lastResult = snapshot.docs[resultSize - 1]
    //     setLast(resultSize === pageSize ? lastResult : null)
    //     // console.log("first= ", firstResult.data())
    //     // console.log("last = ", lastResult.data())
    //   }else{
    //     setLast(null)
    //     if(action === "search")
    //       setDocList([])
    //   }
    // })



  })
  // console.log(`master = `, masterLog)
}

export const listData = (master, setMaster, masterQuery) => {
  let masterLog = []
  masterQuery.forEach((m) => { 
    // console.log("m = ", m)
    const { tableName, columnNameList, whereList, orderList, limit } = m
    let collection = db.collection(tableName)
    whereList.forEach(param => {
      const {key, operation, value: value2} = param
      // console.log(`${key} ${operation} ${value2}`)
      collection = collection.where(key, operation || '==', value2)
    })
    if(orderList && orderList.length > 0)
      orderList.forEach(param => {
        const {orderBy, orderMode} = param
        collection = collection.orderBy(orderBy, orderMode)
        // collection = collection.orderBy("createDate", "desc")
      })
    
    collection.limit(limit || defaultPageLimit)
    .onSnapshot(function func(data){
      const list = data.docs.map(doc => {
      let result = {id : doc.id}
      if(columnNameList && columnNameList.length > 0)
        columnNameList.forEach(columnName => {
          result = {...result, [columnName]:doc.data()[columnName]}
        })
      return result 
    })
      // masterLog.push({name: tableName, value: list})
      // const masterTemp = master;
      // masterTemp[tableName] = list;
      // setMaster(masterTemp);

      masterLog = {...masterLog, [tableName]: list}
      const masterTemp = master
      setMaster({...masterTemp ,...masterLog})
    });
  })
  console.log(`listData = `, masterLog)
}
export const loadMasterUser = (firebase ,master, setMaster, whereList, orderList, limit) => {
  let result = []
  let m = []
   rdb
    .ref("users")
    // .orderByChild("status").equalTo('active')
    // .orderByChild('firstName','desc')
    .orderByChild('firstName')
    .on('value', snapshot => {
      console.log("fetch userMember size = ", snapshot.numChildren());
      snapshot.forEach(function func(childSnapshot) {
        const temp = childSnapshot.val()
        temp.id = childSnapshot.key
        if(temp.status !== 'deleted')
          result = [...result, temp]
          m = [...m, {label: `${temp.firstName} ${temp.lastName}`, value: temp.id} ]
      })  
      // console.log("result = ",m)
      const masterTemp = master;
      masterTemp.user = m;
      setMaster(masterTemp);
      // console.log("master = ",master)
    });
}

export const doSave = (docName, doc, docId, user, nextFunctionCreate, nextFunctionUpdate, mode)=>{ 
  // console.log("docName = ", docName)
  // console.log("doc = ", doc)
  // console.log("docId = ", docId)
  // console.log("user = ", user)
  // console.log("nextFunctionCreate = ", nextFunctionCreate)
  console.log(docId, doc)

  const userCreate = user ? {createUser_id: user.id, createUser: `${user.firstName} ${user.lastName}`, createDate: fb.firestore.FieldValue.serverTimestamp()} : {createDate: fb.firestore.FieldValue.serverTimestamp()}
  const userUpdate = user ? {updateUser_id: user.id, updateUser: `${user.firstName} ${user.lastName}`, updateDate: fb.firestore.FieldValue.serverTimestamp()} : {updateDate: fb.firestore.FieldValue.serverTimestamp()}
  // const userUpdate = user ? {updateUser_id: user.id, updateUser: `${user.firstName} ${user.lastName}`, createDate: fb.firestore.FieldValue.serverTimestamp()} : {createDate: fb.firestore.FieldValue.serverTimestamp()}
  
  if(mode === 'set'){
    db.collection(docName).doc(docId).set({...doc, ...userUpdate})
    .then(function func(){
      openNotificationWithIcon('success','Save Complete','' )
      if(nextFunctionUpdate)
        nextFunctionUpdate()
    })
    .catch(function func(err){
      console.log("err = ",err)
      openNotificationWithIcon('error','Save Error','Please try agian.' )
    })
  }
  else if(docId === 'new' || docId === undefined)
    db.collection(docName).add({...doc, ...userCreate, ...userUpdate})
    .then(function func(result){
      console.log("result.id = ",result.id)
      openNotificationWithIcon('success','Save Complete','' )
      if(nextFunctionCreate)
        nextFunctionCreate(result)
    })
    .catch(function func(err){
      console.log("err = ",err)
      openNotificationWithIcon('error','Save Error','Please try agian.' )
    })
  else
    db.collection(docName).doc(docId).update({...doc, ...userUpdate})
    .then(function func(){
      openNotificationWithIcon('success','Save Complete','' )
      if(nextFunctionUpdate)
        nextFunctionUpdate()
    })
    .catch(function func(err){
      console.log("err = ",err)
      openNotificationWithIcon('error','Save Error','Please try agian.' )
    })
    
  
}
// Update

export const updateDocById = (firebase, docName, clearDoc, doc, docId) => {
  console.info("trying update document with id = ", docId);
  console.info(`docName =  `, JSON.stringify(doc));
  db.collection(docName)
  .doc(docId).set({...doc,updateDate: firebase.serverTimestamp()});
  clearDoc();
  console.log("save sucess");
}

/** * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
storage
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
export const deleteFile = (src) =>{
  console.log("file src = ",src)
  if(src){
    var desertRef = storage.ref().child(src);
    desertRef.delete().then(function func() {
      console.log("delete file success=", src)
      return true
    }).catch(function func(error) {
      console.error("error", error)
      return false
    });
  }
}
export const uploadImage = (firebase, doc, setDoc, path, image) => {
  uploadImageDynamic(firebase, doc, setDoc, "image", image, path)
};
export const uploadImageDynamic = (firebase, doc, setDoc, key, image, path) => {
  // const {storage} = firebase;
  if(image){
    // console.log("image = ", image)
    const newName = `${Math.floor(Math.random()*image.size)}_${image.name}`
    // console.log("newName = ", newName)
    
    if(image.size > uploadFileSizeLimit){
      openNotificationWithIcon('error','File Size Limit',`Please try agin upload file size not over ${uploadFileSizeLimit/1000} KB === ${image.size}` )
      return false
    }
    const uploadTask = storage.ref(`${path}/${newName}`).put(image);
    uploadTask.on(
      "state_changed",
      snapshot => {
        // progress function ...
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      error => {
        // Error function ...
        console.log(error);
      },
      () => {
      // complete function ...
      storage
        .ref(path)
        .child(newName)
        .getDownloadURL()
        .then(url => {
            console.log("path ",path);
            console.log("newName = ",newName);
            console.log("uploaded url = ",url);
            setDoc({ ...doc, [key]: url, [`${key}_src`]: `${path}/${newName}` });
            return true
        });
      }
    );
  }
};

/** * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
ETC
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const noImage = "../appDefault/600x600.webp";

export const getIdFromPath = (point) => {
  const words = window.location.href.split('/');
  let param = ""
  if(words.length === 5){
    const words1 = words[point].split("#")[0];
    param = words1.split("/")[0];
    console.log("getIdFromPath  = ",param);
  }
  return param;
}

export const JsonPretty = (p) =>{
  return <JSONPretty style={{textAlign:"left"}} data={p.data} />;
}
// var local = "th-TH";
// export default function Project() {
// }
export const dateFormat = (date) => {
    // console.log(date);
    if(date != null){
        // return new Date(date.toDate()).toLocaleDateString("en-US");
        return new Intl.DateTimeFormat("th-TH", {
                year: "numeric", month: "long", day: "2-digit",
            }).format(date.toDate());
    }
    return date
}
export const dateValue = (date) => {
  if(date != null && !(date instanceof Date)){
      console.log("date ===== ", JSON.stringify(date) );
      return new Intl.DateTimeFormat("en-US", {
      year: "numeric", month: "2-digit", day: "2-digit",
      }).format(date.toDate());
  } 
  return date;
}
function toDateTime(secs) {
  const t = new Date(1970, 0, 1); // Epoch
  t.setSeconds(secs);
  return t;
}
export const formatDate = (date) => {
  const date1 = date.seconds != null
   ? new Date(date) // Already a javascript date object
   : date;
   return date1;
}

/** * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
changeDocValue changeElement
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
export const addWhereList = (doc, whereList, operation, key) =>{
  // console.log(`last of ${key} = `, key.lastIndexOf("_keywords")!==-1)
  // const keyName = `${key}${operation === 'array-contains' && key.lastIndexOf("_keywords")!==-1 ? `${key}_keywords`:''}`
  const keyName = key
  // console.log("keyName = ", keyName)
  // console.log("doc[key] = ", doc[key])
  
  // if(doc[key] && doc[key].length > 0)
  //   whereList = [...whereList, {key:keyName, operation: operation, value: doc[key][0]}]

  // if(doc[key] && Array.isArray(doc[key]) && doc[key].length > 0)
  //   // whereList = [...whereList, {key:keyName, operation: operation, value: doc[key][0]}]
  //   whereList = [...whereList, {key:keyName, operation: operation, value: doc[key][0]}]
  // else 



  // if(doc[key] && doc[key].length > 0)
  //   whereList = [...whereList, {key:keyName, operation: operation, value: doc[key]}]
  // else
  //   whereList = [...whereList, {key:keyName, operation: null, value: null}]

  if(doc[key] && doc[key].length > 0  && key === 'roles')
    whereList = [...whereList, {key: operation === "array-contains" ? `${keyName}_id` : keyName, operation: operation, value: doc[key]}]
  else if(doc[key] && doc[key].length > 0)
    whereList = [...whereList, {key: operation === "array-contains" ? `${keyName}_keywords` : keyName, operation: operation, value: doc[key]}]
  else
    whereList = [...whereList, {key: keyName, operation: null, value: null}]
    
  return whereList
}
export const defaultWhereList = (doc, whereList) =>{
  if(doc){
    // if(doc.createUser)
    //   addWhereList(doc, whereList, "array-contains", "createUser")
    if(doc.status)
      whereList = [...whereList, {key:"status", operation:"==", value:doc.status}]
    if(doc.createDateFrom)
      whereList = [...whereList, {key:"createDate", operation:">=", value:doc.createDateFrom}]
    if(doc.createDateTo)
      whereList = [...whereList, {key:"createDate", operation:"<=", value:doc.createDateTo}]
    if(doc.createDateRange){
      whereList = [...whereList, {key:"createDate", operation:">=", value:doc.createDateRange[0]}]
      whereList = [...whereList, {key:"createDate", operation:"<=", value:doc.createDateRange[1]}]
    }
  }
  return whereList
}
export const addOrderList = (doc, orderList, orderBy, orderMode) =>{
  if(doc[orderBy])
    orderList = [...orderList, {orderBy: orderBy, orderMode: orderMode}]
  return orderList
}
      // if(doc.createDateFrom)
      //   whereList = [...whereList, {key:"createDate", operation:">=", value:doc.createDateFrom}]
      // if(doc.createDateTo)
      //   whereList = [...whereList, {key:"createDate", operation:"<=", value:doc.createDateTo}]
      // if(doc.createDateRange){
      //   whereList = [...whereList, {key:"createDate", operation:">=", value:doc.createDateRange[0]}]
      //   whereList = [...whereList, {key:"createDate", operation:"<=", value:doc.createDateRange[1]}]
      // }
export const defaultOrderList = [{orderBy: "createDate", orderMode: "desc"}]

const createKeywords = name => {
  const result = [];
  let curName = '';
  name.split('').forEach(letter => {
    curName += letter;
    result.push(curName);
  });
  return result;
}
export const doChangeTextKeywords = (doc, setDoc, event) => { 
  setDoc({ ...doc, [event.target.id]: event.target.value, [`${event.target.id}_keywords`]: createKeywords(event.target.value) }) 
}
// const generateKeywords = names => {
//   const [first, middle, last, sfx] = names;
//   const suffix = sfx.length > 0 ? ` ${sfx}.` : '';
//   const keywordNameWidthoutMiddleName = createKeywords(`${first} ${last}${suffix}`);
//   const keywordFullName = createKeywords(`${first} ${middle} ${last}${suffix}`);
//   const keywordLastNameFirst = createKeywords(`${last}, ${first} ${middle}${suffix}`);
  
//   const middleInitial = middle.length > 0 ? ` ${middle[0]}.` : '';
//   const keywordFullNameMiddleInitial = createKeywords(`${first}${middleInitial} ${last}${suffix}`);
//   const keywordLastNameFirstMiddleInitial = createKeywords(`${last}, ${first}${middleInitial}${suffix}`);
//   return [
//     ...new Set([
//       '',
//       ...keywordFullName,
//       ...keywordLastNameFirst,
//       ...keywordFullNameMiddleInitial,
//       ...keywordLastNameFirstMiddleInitial,
//       ...keywordNameWidthoutMiddleName
//     ])
//   ];
// }
export const createKeywordsList = names => {
  let result = []
  if(Array.isArray(names) && names.length){
    names.forEach(name => {
      result = Object.assign(result, createKeywords(name))
      // result.push(createKeywords(name))
    })
    result = Object.assign(result, createKeywords(names.join(' ')))
    // result.push(createKeywords(`${names.join(' ')}`))
  }
  console.log("createKeywordsList result = ", result)
  return result
}
export const doChangeTextKeywordsList = (doc, setDoc, key, values) => { 
  setDoc({ ...doc, [key]: createKeywordsList(values) }) 
}
export const doChangeText = (doc, setDoc, event) => { setDoc({...doc, [event.target.id]: event.target.value}) }
export const doChangeValue = (doc, setDoc, key, value, index) => { setDoc({...doc,[key]:value}) }

export const doChangeCheckBox = (doc, setDoc, event) => { setDoc({...doc, [event.target.id]: event.target.checked}) }

export const doChangeSelect = (doc, setDoc, key, value, event, nextFunction) => { 
  // setDoc({...doc,[key]: event ? event.label : null, [`${key}_id`]: event ? event.value : null }) 
  if(key && Array.isArray(value)){
    console.log(key)  
    console.log(value)
    console.log(event.map(p => p.label))
    console.log(event.map(p => p.value))
    // console.log(event.map(p => p.label))
    // setDoc({...doc,[key]: event ? event.map(p => p.label) : null, [`${key}_id`]: event ? event.map(p => p.value) : null }) 
    setDoc({...doc,[key]: event ? event.map(p => p.label) : null, [`${key}_id`]: event ? event.map(p => p.value) : null }) 
  }
  else
    setDoc({...doc,[key]: event ? event.label : null, [`${key}_id`]: event ? event.value : null }) 
  if(nextFunction)
    nextFunction()
}
export const doChangeSelectMulti = (doc, setDoc, key, value, event) => { 
  // setDoc({...doc,[key]: event ? event.label : null, [`${key}_id`]: event ? event.value : null }) 
  if(Array.isArray(value)){
    // console.log(value)
    // console.log(event.map(p => p.label))
    setDoc({...doc,[key]: event ? event.map(p => p.label) : null, [`${key}_id`]: event ? event.map(p => p.value) : null }) 
  }
  else
    setDoc({...doc,[key]: event ? event.label : null, [`${key}_id`]: event ? event.value : null }) 
}
// const changeDate = (key, type, date) => { 
//   const nextFunction = (document) => {
//     console.log("nextFunction")
//   }
//   doChangeDate(doc, setDoc, key, type, date, nextFunction) 
// }
export const doChangeDate = (doc, setDoc, key, type, date, nextFunction)=>{
  // console.log("date = ", date)
  if(date){
    if(type === "datetime")
      setDoc({...doc, [key]: date ? date.toDate() : null })
    if(type === "Date") // new Date(), Date in system
      setDoc({...doc, [key]: date ? date : null })
    if(type === "date")
      setDoc({...doc, [key]: date ? date.set({"hour": 0, "minute": 0, "second": 0}).toDate() : null })
    if(type === "time")
      setDoc({...doc, [key]: date ? date.toDate() : null })
    if(type === "from")
      setDoc({...doc, [key]: date ? date.set({"hour": 0, "minute": 0, "second": 0}).toDate() : null })
    if(type === "to")
      setDoc({...doc, [key]: date ? date.set({"hour": 23, "minute": 59, "second": 59}).toDate() : null })
    if(type === "range")
      setDoc({...doc, [key]: [
        date[0].set({"hour": 0, "minute": 0, "second": 0}).toDate()
      , date[1].set({"hour": 23, "minute": 59, "second": 59}).toDate()
      ]})
  }else{
    setDoc({...doc, [key]: null })
  }
  // console.log("doc = ", doc)
  if(nextFunction)
    nextFunction()
}
export const doChangeFile = (doc, setDoc, path, key, image, fileSizeLimit) => {
  if(image){
    const newName = `${Math.floor(Math.random()*image.size)}_${image.name}`
    const limit = fileSizeLimit || uploadFileSizeLimit;
    if(image.size > limit) {
      openNotificationWithIcon('error','File Too Large',`Please upload file size not over ${limit/1000} KB ` )
      console.log("err file size limit = ", image.size)
      return false
    }
    const uploadTask = storage.ref(`${path}/${newName}`).put(image);
    uploadTask.on(
      "state_changed",
      snapshot => {
        // progress function ...
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      error => {
        // Error function ...
        console.log(error);
      },
      () => {
        storage
          .ref(path || 'none_path')
          .child(newName)
          .getDownloadURL()
          .then(url => {
            if(doc && doc[`${key}_src`])
              deleteFile(doc[`${key}_src`])
            console.log("uploaded url = ", url);
            setDoc({ ...doc, 
              [key]: url, 
              [`${key}_src`]: `${path}/${newName}`,
              [`${key}_name`]: image.name,
              [`${key}_size`]: image.size,
              [`${key}_type`]: image.type,
            });
            return true
          })
          .catch(err => {
            console.log("err = ", err);
            openNotificationWithIcon('error','File Upload Fail',`Please try again` )
          })
      }
    );
  }
};

export const doChangeFileDynamic = (inputList, setInputList, image, path, key, index, fileSizeLimit) => {
  if(image){
    const newName = `${Math.floor(Math.random()*image.size)}_${image.name}`
    const limit = fileSizeLimit || uploadFileSizeLimit;
    if(image.size > limit) {
      openNotificationWithIcon('error','File Too Large',`Please upload file size not over ${limit/1000} KB ` )
      console.log("err file size limit = ", image.size)
      return false
    }
    const uploadTask = storage.ref(`${path}/${newName}`).put(image);
    uploadTask.on(
      "state_changed",
      snapshot => {
        // progress function ...
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      error => {
        // Error function ...
        console.log(error);
      },
      () => {
        storage
          .ref(path)
          .child(newName)
          .getDownloadURL()
          .then(url => {
            // if(doc && doc[`${key}_${index}_src`])
            //   deleteFile(doc[`${key}_${index}_src`])
            // console.log("uploaded url = ", url);
            // setDoc({ ...doc, [key]: url, [`${key}_src`]: `${path}/${newName}` });

            if(index){
              // inputList[index]={}
              inputList[index].value = url;  
              // inputList[index] = { element: "image", [key]: url };  
              // setInputList([...inputList, { element: "image", [key]: url }]);
            }
            else
              setInputList([...inputList, { element: "image", [key]: url }]);
            return true
          })
          .catch(err => {
            console.log("err = ", err);
            openNotificationWithIcon('error','File Upload Fail',`Please try again` )
          })
      }
    );
  }
};



export const toEditState = (html) => {
  // if(html !== '<p></p>'){
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorStateResult = EditorState.createWithContent(contentState);
    return editorStateResult
  // }
  // else
  //   return EditorState.createEmpty()
}
export const createMarkup = (html) => {
  if(html)
    return  {
      __html: DOMPurify.sanitize(html)
    }
}

// const [editorState, setEditorState] = React.useState();
export const doChangeEditor = (doc, setDoc, editorState, setEditorState, key, value, index) => { 
  if(value){
    setEditorState({...editorState, [key]: value})
    setDoc({...doc, [key]: draftToHtml(convertToRaw(value.getCurrentContent()))})
    // setDoc({...doc, [key]: value}) 
  }
}



export const doDelete = (docName, id, nextFunction)=>{
  if(!docName){
    console.log("err null docName = ", docName)
    return false
  }
  if(!id){
    console.log("err null id = ", id)
    return false
  }
  db.collection(docName).doc(id).get().then(function func(document){
    if(nextFunction)
      nextFunction(document)
  }).then(function func(){
    db.collection(docName).doc(id).delete() 
    openNotificationWithIcon('info','Deleted Complete','' )
  }).catch(function func(err){
    console.error("err = ",err)
    openNotificationWithIcon('error','Deleted Fail',', Please Try Agian.' )
  })
}
export const doDuplicate = (docName, id, user, nextFunction)=>{
  if(!docName){
    console.log("err null docName = ", docName)
    return false
  }
  if(!id){
    console.log("err null id = ", id)
    return false
  }
  let source = null
  db.collection(docName).doc(id).get().then(function func(document){
    source = document.data()
    if(nextFunction)
      nextFunction(document)
  }).then(function func(){
    const userCreate = user ? {createUser_id: user.id, createUser: `${user.firstName} ${user.lastName}`, createDate: fb.firestore.FieldValue.serverTimestamp()} : {createDate: fb.firestore.FieldValue.serverTimestamp()}
    const userUpdate = user ? {updateUser_id: user.id, updateUser: `${user.firstName} ${user.lastName}`, updateDate: fb.firestore.FieldValue.serverTimestamp()} : {updateDate: fb.firestore.FieldValue.serverTimestamp()}
    db.collection(docName).add({...source, ...userCreate, ...userUpdate})
    .then(function func2(result){
      console.log("result.id = ",result.id)
      openNotificationWithIcon('success','Dupplicate item Complete','' )
      // if(nextFunctionCreate)
      //   nextFunctionCreate(result)
    })
    .catch(function func2(err){
      console.log("err = ",err)
      openNotificationWithIcon('error','Dupplicate item Error','Please try agian.' )
    })
  }).catch(function func(err){
    console.error("err = ",err)
    openNotificationWithIcon('error','Get Source Fail',', Please Try Agian.' )
  })
}


// ----------------------------------------------------------------

// runNumber('trainingChapter', [
//   { key: 'training', value: data.training, id: data.training_id }, 
//   { key: 'chapter', value: data.chapter, id: data.chapter_id }
// ]
// ,[
//   { key: 'statsMemberRegistered', value: 1 }, 
//   { key: 'statsMemberPaid', value: 1 }, 
//   { key: 'paidSummary', value: data.price || 1 }, 
// ]
// ,[
//   { key: 'training', value: doc.training || '' }, 
//   { key: 'training_id', value: doc.training_id || '' },
//   { key: 'chapter', value: doc.chapter || '' }, 
//   { key: 'chapter_id', value: doc.chapter_id || '' },
//   { key: 'userSTChapter', value: `${user.firstName || ''} ${user.lastName || ''}` }, 
//   { key: 'userSTChapter_id', value: user.id || '' },
// ])
export const runNumber = (docNameRun, docKeyId , keyList, valueChangeList, otherValueList) => { 
  let ids = ''
  let keys = ''
  let values = ''
  const options = {}
  keyList.forEach(param => {
    const {id, key, value, updateAllStats} = param
    // console.log("whereList id = ", id)
    // console.log("whereList key = ", key)
    // console.log("whereList value = ", value)
    ids = `${ids !== '' ? `${ids}_` : `${ids}` }${id}`
    keys = `${keys !== '' ? `${keys}_` : `${keys}` }${key}`
    values = `${values !== '' ? `${values} | ` : `${values}` }${value}`
    options.updateAllStats = updateAllStats || true
  })
  let valueChange = {}
  if(valueChangeList && valueChangeList.length)
    valueChangeList.forEach(param => {
      const {key, value} = param
      valueChange = {...valueChange, [key]:fb.firestore.FieldValue.increment(parseInt(value,10))}
    })
  let otherValue = {}
  if(otherValueList && otherValueList.length)
    otherValueList.forEach(param => {
      const {key, value} = param
      otherValue = {...otherValue, [key]:value}
    })
  const docNumber = db.collection(docNameRun).doc(docKeyId)
  return db.runTransaction((trx) => {
    return trx.get(docNumber).then((documentName) => {
      trx.set(docNumber, { 
        _stats_ : { 
          [`_all_`]: {...valueChange},
          [keys] : {
            [`_all_${keys}`]: options.updateAllStats ? {...valueChange} : {},
            [ids] : {
              name : values,
              ...valueChange, 
              ...otherValue, 
              createDate: fb.firestore.FieldValue.serverTimestamp(),
              updateDate: fb.firestore.FieldValue.serverTimestamp(),
            }
          }
        },
      }, { merge: true}); 
    });
  })
  .then(() => {
    console.log("Transaction successfully committed!")
  })
  .catch((error) => console.log("Transaction failed: ", error));
}
// runNumber('trainingChapter', [
//   { key: 'training', value: data.training, id: data.training_id }, 
//   { key: 'chapter', value: data.chapter, id: data.chapter_id }
// ]
// ,[
//   { key: 'statsMemberRegistered', value: 1 }, 
//   { key: 'statsMemberPaid', value: 1 }, 
//   { key: 'paidSummary', value: data.price || 1 }, 
// ]
// ,[
//   { key: 'training', value: doc.training || '' }, 
//   { key: 'training_id', value: doc.training_id || '' },
//   { key: 'chapter', value: doc.chapter || '' }, 
//   { key: 'chapter_id', value: doc.chapter_id || '' },
//   { key: 'userSTChapter', value: `${user.firstName || ''} ${user.lastName || ''}` }, 
//   { key: 'userSTChapter_id', value: user.id || '' },
// ])
export const runNumberWithMultiKey = (docNameRun, keyList, valueChangeList, otherValueList) => { 
  let ids = ''
  let keys = ''
  let values = ''
  keyList.forEach(param => {
    const {id, key, value} = param
    // console.log("whereList id = ", id)
    // console.log("whereList key = ", key)
    // console.log("whereList value = ", value)
    ids = `${ids !== '' ? `${ids}_` : `${ids}` }${id}`
    keys = `${keys !== '' ? `${keys} | ` : `${keys}` }${key}`
    values = `${values !== '' ? `${values} | ` : `${values}` }${value}`
  })
  let valueChange = {}
  if(valueChangeList && valueChangeList.length)
    valueChangeList.forEach(param => {
      const {key, value} = param
      valueChange = {...valueChange, [key]:fb.firestore.FieldValue.increment(value)}
    })
  let otherValue = {}
  if(otherValueList && otherValueList.length)
    otherValueList.forEach(param => {
      const {key, value} = param
      otherValue = {...otherValue, [key]:value}
    })
  const docNumber = db.collection(docNameRun).doc(ids)
  return db.runTransaction((trx) => {
    return trx.get(docNumber).then((documentName) => {
      trx.set(docNumber, { 
        ...valueChange,
        ...otherValue,
        id: ids,
        key: keys,
        value: values,
        // [valueKeyName || 'number']: fb.firestore.FieldValue.increment(valueChangeAmount),
        createDate: fb.firestore.FieldValue.serverTimestamp(),
        updateDate: fb.firestore.FieldValue.serverTimestamp(),
      }, { merge: true});
    });
  })
  .then(() => {
    console.log("Transaction successfully committed!")
  })
  .catch((error) => console.log("Transaction failed: ", error));
}
// QT000001
  // const docPrefix = {prefixName: "QT", numberDigits: 6}
  // QT2021020001
  // const docPrefix = {prefixName: "QT", yearDigits: 4, numberDigits: 6}
  // QT2021020001
  const docPrefixDefault = { 
    yearDigits: 4, monthDigits: 2, numberDigits: 4 ,
    machineValue: 22 , machineDigits: 2, employeeValue: 333, employeeDigits: 4 
  }
  const createDocRun = () => {
    const date = new Date("2022-03-10")
    docNoCreate('order', '1', 'QT', 1, docPrefixDefault, date)
  }
  const deleteDocRun = () => {
    const date = new Date("2022-03-10")
    docNoCreate('order', '1', 'QT', -1, docPrefixDefault, date)
  }

  // const date = new Date("2022-03-10")

  // const docNoIncrement = (docName, id, prefix, date) => docNoCreate(docName, id, prefix, docPrefixDefault, date, 1)
  // const docNoDecrement = (docName, id, prefix, date) => docNoCreate(docName, id, prefix, docPrefixDefault, date, -1)

  export const docNoChange = (docName1, id, prefixName, addValue, date) => { 
    docNoCreate(docName1, id, prefixName, addValue, docPrefixDefault, date)
  }

  export const docNoCreate = (docName1, id, prefixName, addValue, docPrefix, date) => { 
    const { 
      yearDigits, 
      monthDigits, 
      numberDigits,

      machineValue, machineDigits, 
      employeeValue, employeeDigits,
     } = docPrefix

    const docNumber = db.collection("docNumber").doc(docName1)
    const order = db.collection(docName1).doc(id)
    // db.firestore.FieldValue.increment(-1)
    return db.runTransaction((trx) => {
      return trx.get(docNumber).then((documentName) => {
            const yearNumber = (date || new Date()).getFullYear()
            const monthNumber = (date || new Date()).getMonth()+1
            let nextMonthValue = null
            let nextYearValue = null
            let nextValue = null
            if(documentName.exists){
              const docData = documentName.data()
              if(yearDigits && monthDigits){
                console.log("month in ", (docData[`number${yearNumber}${padLeadingZeros(monthNumber,2)}`] || 0))
                nextMonthValue = (docData[`number${yearNumber}${padLeadingZeros(monthNumber,2)}`] || 0) + addValue
              }
              else
                nextMonthValue = addValue
              if(yearDigits)
                nextYearValue = (docData[`number${yearNumber}`] || 0) + addValue
              else
                nextYearValue = addValue
              nextValue = (docData.number || 0) + addValue
            }
            else{
              nextMonthValue = 1
              nextYearValue = 1
              nextValue = 1
            }

            let nextDocNo = ""
            if(prefixName)
              nextDocNo += prefixName
            if(yearDigits)
              nextDocNo += padLeadingZeros(yearNumber, yearDigits)
            if(monthDigits)
              nextDocNo += padLeadingZeros(monthNumber, monthDigits)
            if(numberDigits){
              if(yearDigits && monthDigits)
                nextDocNo += padLeadingZeros(nextMonthValue, numberDigits)
              else if (yearDigits)
                nextDocNo += padLeadingZeros(nextYearValue, numberDigits)
              else
                nextDocNo += padLeadingZeros(nextValue, numberDigits)
            }

            if(machineValue && machineDigits)
              nextDocNo += " "+padLeadingZeros(machineValue, machineDigits)
            if(employeeValue && employeeDigits)
              nextDocNo += " "+padLeadingZeros(employeeValue, employeeDigits)
              
            console.log("nextDocNo = ", nextDocNo)
            trx.set(order, {
              // createDate: fb.firestore.FieldValue.serverTimestamp(),
              updateDate: fb.firestore.FieldValue.serverTimestamp(),
              number: nextValue,
              docNo: nextDocNo,
            });
            trx.set(docNumber, { 
              [`number${yearNumber}${padLeadingZeros(monthNumber,2)}`]: nextMonthValue,
              [`number${yearNumber}`]: nextYearValue,
              number: nextValue,
              docNo: nextDocNo,
            }, { merge: true});
          // }
        });
      })
      .then(() => {
        // getDocNo('order')
        console.log("Transaction successfully committed!")
      })
      .catch((error) => console.log("Transaction failed: ", error));
  }
  const padLeadingZeros = (num, size) => {
    var str = num+"";
    while (str.length < size) str = "0" + str;
    return str;
  }
            // const runDocNo = () => { 
            //   const docNumber = db.collection("docNumber").doc(docName1);
            //   const order = db.collection(docName1).doc(id);
            //   // db.firestore.FieldValue.increment(-1)
            //   return db.runTransaction((trx) => {
            //     return trx.get(docNumber).then((documentName) => {
            //           const docData = documentName.data()
            //           const nextValue = (documentName.exists ? docData.number : 0) + addValue
            //           const nextDocNo = prefixName + padLeadingZeros(nextValue, prefixZero)
            //           console.log("nextDocNo = ", nextDocNo)
            //           trx.set(order, {
            //             // createDate: fb.firestore.FieldValue.serverTimestamp(),
            //             updateDate: fb.firestore.FieldValue.serverTimestamp(),
            //             number: nextValue,
            //             docNo: nextDocNo,
            //           });
            //           trx.set(docNumber, { 
            //             number: nextValue,
            //             docNo: nextDocNo,
            //           }, { merge: true});
            //         // }
            //       });
            //     })
            //     .then(() => {
            //       getDocNo()
            //       console.log("Transaction successfully committed!")
            //     })
            //     .catch((error) => console.log("Transaction failed: ", error));
            // }


            // {customHelmet(
            //   company.companyIcon
            //   ,helmet.logo192
            //   ,helmet.logo512
            //   ,manifest
            //   ,helmet.title
            //   ,helmet.keywords
            //   ,helmet.description
            //   ,helmet.author
            //   ,helmet.url)
            // }
export const customHelmet = (favicon,logo192,image,mainifest,title,keywords,description,author,url) =>{
  return (
    <Helmet>
      <title>{title}</title>
      <link rel="icon" href={favicon} />
      <link rel="apple-touch-icon" href={logo192} />
      <link rel="manifest" href={mainifest} />

      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta http-equiv="Content-type" content="text/html; charset=utf-8"/>
      <meta name="theme-color" content="#000000" />

      <meta name="title" content={title} />
      <meta name="keywords" content={keywords} />
      <meta name="description" content={description} />
      <meta name="author" content={author} />

      <meta property="og:url" content={url} />
      <meta property="og:type" content={title} />
      <meta property="og:title" content={keywords} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={keywords} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  )
}
export const customManifest = (favicon, logo192, logo512, short_name, name) =>{
  return {
    "short_name": short_name,
    "name": name,
    "icons": [
      {
        "src": favicon,
        "sizes": "64x64 32x32 24x24 16x16",
        "type": "image/x-icon"
      },
      {
        "src": logo192,
        "type": "image/png",
        "sizes": "192x192"
      },
      {
        "src": logo512,
        "type": "image/png",
        "sizes": "512x512"
      }
    ],
    "start_url": ".",
    "display": "standalone",
    "theme_color": "#000000",
    "background_color": "#ffffff"
  }

}