// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAiYbgM_9Nd7JggL71dQH9EEtm-uJvyRu4",
  authDomain: "shin-aa.firebaseapp.com",
  projectId: "shin-aa",
  storageBucket: "shin-aa.appspot.com",
  messagingSenderId: "281512204532",
  appId: "1:281512204532:web:715d4606732db0bd18e6a1",
  measurementId: "G-TQ9HD71YHE"
};
export default firebaseConfig 