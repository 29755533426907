import 'antd/lib/style/index.less' // antd core styles
import './@kit/vendors/antd/themes/default.less' // default theme antd components
import './@kit/vendors/antd/themes/dark.less' // dark theme antd components
import './global.scss' // app & third-party component styles

import React from 'react'
import ReactDOM from 'react-dom'
import { createHashHistory } from 'history'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import { logger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'

import reducers from './redux/reducers' // store DATA, INITAIL and use from compose connect
import sagas from './redux/sagas' // store FUNCTION and use from compose connect dispatch
import Localization from './localization'
// import Router from './router' // navigaton route and switch for load UI components


// import Localization from './app/gentlestots/localization'
// import Router from './app/gentlestots/router'
// import Router from './app/salePage/router'
// import Router from './app/getSmile/router'
// import Router from './app/labelle/router'
// import Router from './app/bnithai/router'
import Router from './app/shin-a/router'
// import Router from './app/bni-integrity/router'
// import Router from './app/ybkk/router'
// import Router from './app/housed/router'
// import Router from './app/webg/router'

// import Router from './app/weget/router'
// import firebaseConfig from './app/labelle/firebaseConfig'


import * as serviceWorker from './serviceWorker'
import Firebase, { FirebaseContext } from './components/Firebase';
// mocking api
import 'services/axios/fakeApi'

// middlewared
const history = createHashHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [sagaMiddleware, routeMiddleware]
if (process.env.NODE_ENV === 'development') {
  // middlewares.push(logger)
}
const store = createStore(reducers(history), compose(applyMiddleware(...middlewares)))
sagaMiddleware.run(sagas)

// ReactDOM.render(
//   <Provider store={store}>
//     <Localization>
//       <Router history={history} />
//     </Localization>
//   </Provider>,
//   document.getElementById('root'),
// )
ReactDOM.render(
  <Provider store={store}>
    <FirebaseContext.Provider value={new Firebase()}>
      <Localization>
        <Router history={history} />
      </Localization>
    </FirebaseContext.Provider>
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
export { store, history }
