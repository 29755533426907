import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import style from './style.module.scss'

const mapStateToProps = ({ settings }) => ({ settings })

// const Footer = ({ settings: { isContentMaxWidth, logo, description } }) => {
const Footer = ({ settings: { isContentMaxWidth } }) => {
  return (
    <div
      className={classNames(style.footer, {
        [style.footerFullWidth]: !isContentMaxWidth,
      })}
    >
      <div className={style.inner}>
        <div className="row">
          <div className="col-md-8">
            <p>
              <strong>Shin A Service</strong>
            </p>
            <p>
              Shin A Service Online Management
            </p>
            <a
              href="https://weget.co.th"
              target="_blank"
              rel="noopener noreferrer"
              className={style.sellpixels}
            >
              Shin A Service
              <span />
            </a>
            <br />
            <p className="mb-0">
              Copyright © 2015-{new Date().getFullYear()} Shin A Service CO.,LTD. |{' '}
              <a href="https://www.weget.co.th/privacy" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>
            </p>

            <p className="mb-0">
              Developed By |{' '}
              <a href="https://www.weget.co.th" target="_blank" rel="noopener noreferrer">
                WEGET
              </a>
            </p>
          </div>
          <div className="col-md-4">
            <div style={{marginTop:"15px"}}>
              {/* <a href="https://www.lazada.co.th/shop/gentle-and-natural/?spm=a2o4m.pdp.seller.1.43d5559bxNsFZD&itemId=1317324711&channelSource=pdp" target="_blank" rel="noopener noreferrer"><img src="etc/logos/social/logo_lazada.webp" style={{marginLeft: "15px", marginTop:"-40px"}} width="62px" alt="social" /></a>
              <a href="https://shopee.co.th/gentles_tots?categoryId=58&itemId=6441237640" target="_blank" rel="noopener noreferrer"><img src="etc/logos/social/logo_shopee.webp" style={{marginLeft: "15px", marginTop:"-40px"}} width="62px" alt="social" /></a> */}
              {/* <a href="http://" target="_blank" rel="noopener noreferrer"><i className="fa fa-3x fa-line"></i></a> */}
              {/* <a href="http://" target="_blank" rel="noopener noreferrer"><i className="fa fa-3x fa-twitter"></i></a>
              <a href="http://" target="_blank" rel="noopener noreferrer"><i className="fa fa-3x fa-linkedin"></i></a> */}


              {/* <a href="http://line.me/ti/p/~%40bzz0674k" target="_blank" rel="noopener noreferrer"><img src="etc/logos/social/logo_line.webp" style={{marginLeft: "15?px", marginTop:"-23px"}} width="40px" alt="social" /></a>
              <a href="https://www.facebook.com/shin-aeevelopment/" target="_blank" rel="noopener noreferrer" style={{marginLeft: "15px", color:"#3b5998"}}><i className="fa fa-3x fa-facebook-square"></i></a>
              <a href="https://www.youtube.com/channel/UCcT1Z6PRZHE0Epm9VQiF9gQ" target="_blank" rel="noopener noreferrer" style={{marginLeft: "15px", color:"#FF0000"}}><i className="fa fa-3x fa-youtube-play"></i></a>
              <a href="https://www.instagram.com/shin-aeevelopment/" target="_blank" rel="noopener noreferrer" style={{marginLeft: "15px", color:"#9b6954"}}><i className="fa fa-3x fa-instagram"></i></a> */}
            </div>
            <div className={style.logo}>
              <div>
                <img
                  src="app/shin-a/logo/android-chrome-512x512.png"
                  alt="logo"
                  style={{ maxWidth: '40px' }}
                />
              </div>
            </div>
          </div>
          {/* <div className="col-md-4">
            <div className={style.logo}>
              <div>
                <img
                  src="app/shin-a/logo/android-chrome-512x512.png"
                  alt="logo"
                  style={{ maxWidth: '40px' }}
                />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(Footer)
