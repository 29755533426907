import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'
import { googleAnalytic } from 'config/dbUtils'
import projectConfig from './config'

import Layout from './layouts'

const projectName = "shin-a"

const routes = [
  // test
  { path: '/editorList', Component: lazy(() => import('app/custom/pages/editor/list'))},
  { path: '/editor/:id', Component: lazy(() => import('app/custom/pages/editor/edit'))},
  
  { path: '/testList', Component: lazy(() => import('app/custom/pages/test/list'))},
  { path: '/test/:id', Component: lazy(() => import('app/custom/pages/test/edit'))},

  { path: '/testNewList', Component: lazy(() => import('app/custom/pages/testNew/list'))},
  { path: '/testNewListEdit', Component: lazy(() => import('app/custom/pages/testNew/listEdit'))},
  { path: '/testNew/:id', Component: lazy(() => import('app/custom/pages/testNew/edit'))},

  { path: '/test3List', Component: lazy(() => import('app/custom/pages/test3/list'))},
  { path: '/test3/:id', Component: lazy(() => import('app/custom/pages/test3/edit'))},
  { path: '/test_normal', Component: lazy(() => import('app/custom/pages/test_normal/list'))},


  // { path: '/contentList', Component: lazy(() => import('app/shin-a/pages/content/list'))},
  { path: '/contentList', Component: lazy(() => import('app/shin-a/pages/content/listCustom'))},
  // { path: '/content/:id', Component: lazy(() => import('app/shin-a/pages/content/edit'))},
  { path: '/content/:id', Component: lazy(() => import('app/shin-a/pages/content/editCustom'))},

  // { path: '/campaignList', Component: lazy(() => import('app/shin-a/pages/campaign/list'))},
  { path: '/campaignList', Component: lazy(() => import('app/shin-a/pages/campaign/listCustom'))},
  // { path: '/campaign/:id', Component: lazy(() => import('app/shin-a/pages/campaign/edit'))},
  { path: '/campaign/:id', Component: lazy(() => import('app/shin-a/pages/campaign/editCustom'))},
  { path: '/campaignView/:id', Component: lazy(() => import('app/shin-a/pages/campaign/showCustom'))},

  { path: '/influencerList', Component: lazy(() => import('app/shin-a/pages/influencer/list'))},
  { path: '/influencer/:id', Component: lazy(() => import('app/shin-a/pages/influencer/edit'))},

  { path: '/customerList', Component: lazy(() => import('app/shin-a/pages/customer/list'))},
  { path: '/customer/:id', Component: lazy(() => import('app/shin-a/pages/customer/edit'))},

  { path: '/contentTypeList', Component: lazy(() => import('app/shin-a/pages/contentType/list'))},
  { path: '/contentType/:id', Component: lazy(() => import('app/shin-a/pages/contentType/edit'))},


  
  // { path: '/test_full', Component: lazy(() => import('app/custom/pages/test_full/list'))},
  // dynamic
  { path: '/devInit', Component: lazy(() => import('app/custom/pages/zystem/devInit'))},
  { path: '/masterList', Component: lazy(() => import('app/custom/pages/master/list'))},
  { path: '/master/:id', Component: lazy(() => import('app/custom/pages/master/edit'))},
  // user
  // { path: '/userList', Component: lazy(() => import('app/custom/pages/user/list'))},
  // { path: '/user/:id', Component: lazy(() => import('app/custom/pages/user/edit'))},
  // Custom
  { path: '/userMyProfile', Component: lazy(() => import('app/custom/pages/userMyProfile')), exact: true,},


  // project/shin-a/ PROJECT ///////////////////////////////////////////////////////////////

  { path: '/home/thankYou', Component: lazy(() => import('app/shin-a/pages/home/thankYou'))},
  { path: '/home/privacy', Component: lazy(() => import('app/shin-a/pages/home/privacy'))},
  // home
  { path: '/home/video/:id', Component: lazy(() => import('app/shin-a/pages/home/videoDetail'))},
  { path: '/home/promotion/:id', Component: lazy(() => import('app/shin-a/pages/home/promotionDetail'))},


  { path: '/home/home', Component: lazy(() => import('app/shin-a/pages/home/home'))},
  { path: '/home/homeRegister', Component: lazy(() => import('app/shin-a/pages/home/homeRegister'))},
  { path: '/home/about', Component: lazy(() => import('app/shin-a/pages/home/about'))},
  { path: '/home/contact', Component: lazy(() => import('app/shin-a/pages/home/contact'))},
  { path: '/home/promotion', Component: lazy(() => import('app/shin-a/pages/home/promotion'))},
  { path: '/home/salePromotion', Component: lazy(() => import('app/shin-a/pages/home/salePromotion'))},
  { path: '/home/servicePromotion', Component: lazy(() => import('app/shin-a/pages/home/servicePromotion'))},
  { path: '/home/service', Component: lazy(() => import('app/shin-a/pages/home/service'))},
  { path: '/home/video', Component: lazy(() => import('app/shin-a/pages/home/video'))},
  { path: '/home/passage', Component: lazy(() => import('app/shin-a/pages/home/passage'))},

  { path: '/home/blog', Component: lazy(() => import('app/shin-a/pages/home/blog'))},

  


  // admin
  { path: '/blogList', Component: lazy(() => import('app/shin-a/pages/blog/list'))},
  { path: '/blog/:id', Component: lazy(() => import('app/shin-a/pages/blog/edit'))},

  { path: '/blogGroupList', Component: lazy(() => import('app/shin-a/pages/blogGroup/list'))},
  { path: '/blogGroup/:id', Component: lazy(() => import('app/shin-a/pages/blogGroup/edit'))},

  { path: '/homeSlideList', Component: lazy(() => import('app/shin-a/pages/homeSlide/list'))},
  { path: '/homeSlide/:id', Component: lazy(() => import('app/shin-a/pages/homeSlide/edit'))},

  { path: '/productList', Component: lazy(() => import('app/shin-a/pages/product/list'))},
  { path: '/product/:id', Component: lazy(() => import('app/shin-a/pages/product/edit'))},

  { path: '/videoList', Component: lazy(() => import('app/shin-a/pages/video/list'))},
  { path: '/video/:id', Component: lazy(() => import('app/shin-a/pages/video/edit'))},

  { path: '/videoGroupList', Component: lazy(() => import('app/shin-a/pages/videoGroup/list'))},
  { path: '/videoGroup/:id', Component: lazy(() => import('app/shin-a/pages/videoGroup/edit'))},

  { path: '/promotionList', Component: lazy(() => import('app/shin-a/pages/promotion/list'))},
  { path: '/promotion/:id', Component: lazy(() => import('app/shin-a/pages/promotion/edit'))},

  { path: '/promotionGroupList', Component: lazy(() => import('app/shin-a/pages/promotionGroup/list'))},
  { path: '/promotionGroup/:id', Component: lazy(() => import('app/shin-a/pages/promotionGroup/edit'))},

  { path: '/registerLeadList', Component: lazy(() => import('app/shin-a/pages/registerLead/list'))},
  { path: '/registerLead/:id', Component: lazy(() => import('app/shin-a/pages/registerLead/edit'))},

  { path: '/registerServiceList', Component: lazy(() => import('app/shin-a/pages/registerService/list'))},
  { path: '/registerService/:id', Component: lazy(() => import('app/shin-a/pages/registerService/edit'))},

  { path: '/budgetList', Component: lazy(() => import('app/shin-a/pages/budget/list'))},
  { path: '/budget/:id', Component: lazy(() => import('app/shin-a/pages/budget/edit'))},

  { path: '/serviceTypeList', Component: lazy(() => import('app/shin-a/pages/serviceType/list'))},
  { path: '/serviceType/:id', Component: lazy(() => import('app/shin-a/pages/serviceType/edit'))},

  { path: '/projectImageList', Component: lazy(() => import('app/shin-a/pages/projectImage/list'))},
  { path: '/projectImage/:id', Component: lazy(() => import('app/shin-a/pages/projectImage/edit'))},





  // admin
  { path: '/blogList', Component: lazy(() => import('app/gentlestots/pages/blog/list'))},
  { path: '/blog/:id', Component: lazy(() => import('app/gentlestots/pages/blog/edit'))},

  { path: '/blogGroupList', Component: lazy(() => import('app/gentlestots/pages/blogGroup/list'))},
  { path: '/blogGroup/:id', Component: lazy(() => import('app/gentlestots/pages/blogGroup/edit'))},

  { path: '/contactRegisterList', Component: lazy(() => import('app/gentlestots/pages/contactRegister/list'))},
  { path: '/contactRegister/:id', Component: lazy(() => import('app/gentlestots/pages/contactRegister/edit'))},

  // userMyProfile

  // { path: '/companyList', Component: lazy(() => import('app/shin-a/pages/company/list'))},
  // { path: '/company/:id', Component: lazy(() => import('app/shin-a/pages/company/edit'))},

  // RIS PROJECT ///////////////////////////////////////////////////////////////
  // // my
  // { path: '/myCredit', Component: lazy(() => import('app/shin-a/pages/my/myCredit'))},
  // user
  { path: '/userList', Component: lazy(() => import('app/shin-a/pages/user/list'))},
  { path: '/user/:id', Component: lazy(() => import('app/shin-a/pages/user/edit'))},
  // // user
  // { path: '/userPointList', Component: lazy(() => import('app/shin-a/pages/userPoint/list'))},
  // { path: '/userPoint/:id', Component: lazy(() => import('app/shin-a/pages/userPoint/edit'))},
  // // // service
  // // { path: '/serviceList', Component: lazy(() => import('app/shin-a/pages/service/list'))},
  // // { path: '/service/:id', Component: lazy(() => import('app/shin-a/pages/service/edit'))},
  // // product
  // { path: '/productList', Component: lazy(() => import('app/shin-a/pages/product/list'))},
  // { path: '/product/:id', Component: lazy(() => import('app/shin-a/pages/product/edit'))},
  // // productGroup
  // { path: '/productGroupList', Component: lazy(() => import('app/shin-a/pages/productGroup/list'))},
  // { path: '/productGroup/:id', Component: lazy(() => import('app/shin-a/pages/productGroup/edit'))},
  // // saleProduct
  // { path: '/saleProductList/:id', Component: lazy(() => import('app/shin-a/pages/saleProduct/list'))},
  // { path: '/saleProductList',     Component: lazy(() => import('app/shin-a/pages/saleProduct/list'))},
  // { path: '/saleProduct/:id', Component: lazy(() => import('app/shin-a/pages/saleProduct/edit'))},
  // { path: '/saleProductView/:id', Component: lazy(() => import('app/shin-a/pages/saleProduct/view'))},
  // // saleProductSharing
  // { path: '/saleProductSharingList/:transaction_id', Component: lazy(() => import('app/shin-a/pages/saleProductSharing/list'))},
  // { path: '/saleProductSharingList', Component: lazy(() => import('app/shin-a/pages/saleProductSharing/list'))},
  // { path: '/saleProductSharing/:id', Component: lazy(() => import('app/shin-a/pages/saleProductSharing/edit'))},
  // { path: '/mySaleProductSharingList', Component: lazy(() => import('app/shin-a/pages/saleProductSharing/myList'))},

  // Auth Pages
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/register',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
  },
  {
    path: '/auth/lockscreen',
    Component: lazy(() => import('pages/auth/lockscreen')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => {

  React.useEffect(() => {
    googleAnalytic({tracking_id : 'UA-120432092-1'})
  },[]);
  
  return (
    <ConnectedRouter history={history}>
      { window.location.href.indexOf("/page/")>-1 || window.location.href.indexOf("/page/")>-1 
      ?
      <div className="c-layout-page">
        {/* <Route exact path="/web/:id" component={WebShow}/> */}
        {routes.map(({ path, Component, exact }) => (
          <Route
            path={path}
            key={path}
            exact={exact}
            render={() => {
              return (
                <div className={routerAnimation}>
                  <Suspense fallback={null}>
                    <Component />
                  </Suspense>
                </div>
              )
            }}
          />
        ))}
      </div>
      :
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                {/* {console.log(location)} */}
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    googleAnalytic()
                    {/* <Route exact path="/" render={() => <Redirect to="/dashboard/analytics" />} /> */}
                    {/* <Route exact path="/" render={() => <Redirect to="/shin-aList" />} /> */}
                    
                    {
                      projectConfig && projectConfig.frontOffice === 'active'
                      ? <Route exact path="/" render={() => <Redirect to="/home/home" />} />
                      : <Route exact path="/" render={() => <Redirect to="/userMyProfile" />} />
                    }
                    <Route exact path="/loginSuccess" render={() => <Redirect to="/userMyProfile" />} />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={(props) => {
                          return (
                            <div className={routerAnimation} >
                              <Suspense fallback={null} >
                                {/* props.match.params = {JSON.stringify(props.match.params)} */}
                                <Component props={props} />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
      }
    </ConnectedRouter>
  )
}
export default connect(mapStateToProps)(Router)