import actions from './actions'

const initialState = {
  id: '',
  name: '',
  firstName: '',
  lastName: '',
  nickName: '',
  mobile: '',
  role: '',
  roles: '',
  email: '',
  photo: '',
  photoBackground: '',
  // avatar: '',
  // image:'',
  authorized: process.env.REACT_APP_AUTHENTICATED || false, // false is default value
  loading: false,

  uid:'',
  creditBalance:0,
  pointBalance:0,

  company: '',
  company_id: '',
  companyRole: '',
  
  position: '',
  companyName: '',
  companyDesc: '',
  companyLogo: '',

  chapter: '',
  chapter_id: '',
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
